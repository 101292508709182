<template>
  <div
    class="flex h-full items-center tabular-nums"
    :class="`${params.justifyStart ? 'justify-start' : 'justify-end'} `"
  >
    <span :class="`${params.value ? (params.value > 0 ? 'text-green-400' : 'text-red-400') : 'text-gray-500'}`">
      {{ cellValue }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { getCurrencySymbol, formatNumber } from '@/composeables/filters';
import { rounded } from '@/composeables/filters';

const props = defineProps({
  params: {
    type: Object,
    default: undefined
  }
});

const cellValue = computed(() => {
  if (props.params.value == 0) return 0;
  if (!props.params.value) return '-';
  const leading_sign = props.params.value > 0 ? '+' : '-';
  return `${leading_sign}${rounded(Math.abs(props.params.value * 100, 2))}%`;
});
</script>
